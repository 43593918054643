import { Helmet } from 'react-helmet'
import React from 'reactn'
import styled from 'styled-components/macro'
import Layout from '../components/Layout'
import { MarkdownRenderer } from '../components/MarkdownRenderer'
import { useAppContext } from '../context'
import Breadcrumb from '../models/Breadcrumb'
import { SettingKey } from '../models/GlobalSetting'
import { buildMetaTitle } from '../utils/meta'

interface Props {
	path: string
	pageContext: {
		markdown?: string
		pageName?: string
		parentLink?: string
		parentLinkDisplay?: string
		rootLink?: string
		rootLinkDisplay?: string
	}
}

export default function MdPage(props: Props) {
	const { getSettingValue } = useAppContext()

	const {
		path,
		pageContext: {
			markdown,
			pageName,
			parentLink,
			parentLinkDisplay,
			rootLink,
			rootLinkDisplay,
		},
	} = props

	const breadcrumbs: Breadcrumb[] = []

	if (rootLink)
		breadcrumbs.push({ link: rootLink, display: rootLinkDisplay || rootLink })

	if (parentLink)
		breadcrumbs.push({
			link: parentLink,
			display: parentLinkDisplay || parentLink,
		})

	if (pageName) breadcrumbs.push({ link: path, display: pageName })

	const metaTitle = getSettingValue(SettingKey.META_TITLE)

	return (
		<Layout breadcrumbs={breadcrumbs}>
			<Helmet>
				<title>{buildMetaTitle([pageName, metaTitle])}</title>
			</Helmet>
			<Container>
				<MarkdownRenderer children={markdown ?? 'No content'} />
			</Container>
		</Layout>
	)
}

const Container = styled.div`
	margin: 0 auto;
`
